import React, { useEffect } from "react";
import { observe } from "selector-observer";
import { render } from "react-dom";
import { Image } from "../../../../scripts/react/Image";
import { Karousel } from "../../../../scripts/react/Carousels";
import { DefaultClasses } from "../../../../scripts/react/Carousels/defaults";

export const LocationCard = (props) => {
    const {
        imageSrc,
        imageAlt,
        eyebrow,
        name,
        pagesAndAnswersName,
        addressLine1,
        addressLine2,
        addressLine3,
        ctaLink,
        ctaLabel,
        ctaTarget
    } = props;

    return (
        <div className="location-card">
            <Image alt={imageAlt} src={imageSrc} />
            <header>
                <span>{eyebrow}</span>
                <h3>{name}</h3>
                {/*This will only show for Yext Queried Location Cards*/}
                <h3>{pagesAndAnswersName}</h3>
            </header>
            <div className="location-card__details">
                <p>{addressLine1}</p>
                <p>{addressLine2}</p>
                <p>{addressLine3}</p>
            </div>
            {ctaLink &&
                <a title={ctaLabel} aria-label={ctaLabel} href={ctaLink} className="btn" target={ctaTarget}>
                    <span className="btn-label">{ctaLabel}</span>
                    <span className="btn-round btn-sm btn-primary">
                        <span className="fas fa-arrow-right" />
                    </span>
                </a>
            }
        </div>
    );
};

const getKarouselOptions = (length) => {
    return {
        classes: DefaultClasses,
        draggable: true,
        indicators: length > 1,
        responsive: [
            {
                breakpoint: 1200,
                options: {
                    slidesToShow: 3,
                    draggable: true,
                    buttons: length > 3,
                    indicators: false,
                },
            },
            {
                breakpoint: 768,
                options: { slidesToShow: 3, indicators: length > 3 },
            },
        ],
        slidesToShow: 1.25,
    };
}


export const LocationCards = (props) => {
    const { disableAnimation, cards } = props;
    const karouselOptions = getKarouselOptions(cards.length);
    return (cards.length > 0 &&
        <Karousel
            animationDisabled={disableAnimation}
            {...karouselOptions}
        >
            {cards.map((card, index) => (
                <LocationCard {...card} key={index} />
            ))}
        </Karousel>
    );
};


 document.addEventListener("readystatechange", () => {
     if (document.readyState === "interactive") {
         observe('[data-component="locationcards"]', {
             add(element) {
                 const { dataset } = element as HTMLElement;
                 const { propsId } = dataset;
                 const componentData = window.ucsdh[`locationcards_${propsId}`];
                 render(<LocationCards {...componentData} />, element);
             },
         });
     }
 });